import REDUCER_CONSTANTS from '../constants/reducerConstants'
const initialState = {
}

function moduleQVReducer(state=initialState, action){
  let{
    GET_VEHICLE_MODULE_QV,
  } = REDUCER_CONSTANTS
  switch(action.type){
    case GET_VEHICLE_MODULE_QV:
      return{
        ...state,
        module:action.module,
        moduleChecked:true,
      }
    default:
      return state
  }
}
export default moduleQVReducer
