import React from 'react'
import MODULE_TYPES from '../common/common-components/modules/moduleTypes'
import ModulePdf from '../common/common-components/modules/modulePdf'
import ModuleImage from '../common/common-components/modules/moduleImage'
import ModuleHtml5Video from '../common/common-components/modules/moduleHtml5Video'
import ModuleiFrame from '../common/common-components/modules/moduleiFrame'
import ModuleYoutubeVideo from '../common/common-components/modules/moduleYoutubeVideo'
import ModuleHtml from '../common/common-components/modules/moduleHtml'
import ModuleHtmlUrl from '../common/common-components/modules/moduleHtmlUrl'
import Module3PA from '../common/common-components/modules/module3PA'
import ModuleJson from '../common/common-components/modules/moduleJson'
import './moduleQVApp.scss'

function ModuleQVApp(props){
  let {module} = props
  return(
    <div className="module-qv-app">
      {getModule(module)}

    </div>
  )
}
function getModule(module){
  switch(module.content_type){
    case MODULE_TYPES._PDF:
      return <ModulePdf module={module}/>
    case MODULE_TYPES._IMAGE:
      return <ModuleImage module={module}/>
    case MODULE_TYPES._HTML5_VIDEO:
      return <ModuleHtml5Video module={module} />
    case MODULE_TYPES._IFRAME:
      return <ModuleiFrame module={module} />
    case MODULE_TYPES._YOUTUBE_VIDEO:
      return <ModuleYoutubeVideo module={module}/>
    case MODULE_TYPES._HTML:
      return <ModuleHtml module={module}/>
    case MODULE_TYPES._HTML_URL:
      return <ModuleHtmlUrl module={module}/>
    case MODULE_TYPES._3PA:
      return <Module3PA module={module} redirect={true}/>
    case MODULE_TYPES._JSON:
      return <ModuleJson module={module}/>
  }
  return(<div>Module Unsupported</div>)
}
export default ModuleQVApp
