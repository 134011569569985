import React, {useState, useEffect} from 'react'
import './styles/modulesModal.scss'
import MODULE_TYPES from '../../common/common-components/modules/moduleTypes'
import MODAL_TYPES from './modalTypes'
import DEFAULT_APP_UTILS from '../defaultAppUtils'
import ModulePdf from '../../common/common-components/modules/modulePdf'
import ModuleImage from '../../common/common-components/modules/moduleImage'
import ModuleHtml5Video from '../../common/common-components/modules/moduleHtml5Video'
import ModuleiFrame from '../../common/common-components/modules/moduleiFrame'
import ModuleYoutubeVideo from '../../common/common-components/modules/moduleYoutubeVideo'
import ModuleHtml from '../../common/common-components/modules/moduleHtml'
import ModuleHtmlUrl from '../../common/common-components/modules/moduleHtmlUrl'
import Module3PA from '../../common/common-components/modules/module3PA'
import ModuleJson from '../../common/common-components/modules/moduleJson'
import HamburgerIcon from '../../common/common-assets/icons/hamburgericon'
import HamburgerIconClose from '../../common/common-assets/icons/hamburgericonclose'
import CloseIcon from '../../common/common-assets/icons/closeicon'
import ArrowRight from '../../common/common-assets/icons/arrowRight'
import ArrowLeft from '../../common/common-assets/icons/arrowLeft'

import {connect} from 'react-redux'

function ModulesModal(props){
  let{ closeModalConnect, getDjapiHostedModuleConnect, index, defaultApp} = props
  let {vehicleModules} = defaultApp
  useEffect(()=>{
    if(vehicleModules[index].djapi_hosted && !vehicleModules[index].payload){
      getDjapiHostedModuleConnect(vehicleModules,index)
    }
  })
  if(vehicleModules[index]){
    if(vehicleModules[index].djapi_hosted && !vehicleModules[index].payload){
      return(
        <div>
          Loading
        </div>
      )
    }
    else{
      return(
        <React.Fragment>
          <ModuleControls {...props}/>
          <div className="modules-modal">
            {getModule(vehicleModules[index])}
          </div>
        </React.Fragment>
      )
    }
  }
}
function getModule(module){
  switch(module.content_type){
    case MODULE_TYPES._PDF:
      return <ModulePdf module={module}/>
    case MODULE_TYPES._IMAGE:
      return <ModuleImage module={module}/>
    case MODULE_TYPES._HTML5_VIDEO:
      return <ModuleHtml5Video module={module} />
    case MODULE_TYPES._IFRAME:
      return <ModuleiFrame module={module} />
    case MODULE_TYPES._YOUTUBE_VIDEO:
      return <ModuleYoutubeVideo module={module}/>
    case MODULE_TYPES._HTML:
      return <ModuleHtml module={module}/>
    case MODULE_TYPES._HTML_URL:
      return <ModuleHtmlUrl module={module}/>
    case MODULE_TYPES._3PA:
      return <Module3PA module={module} redirect={true}/>
    case MODULE_TYPES._JSON:
      return <ModuleJson module={module}/>
  }
  return(<div>Module Unsupported</div>)
}
function ModuleControls(props){
  let{showModalConnect, closeModalConnect, index, defaultApp} = props
  let {vehicleModules} = defaultApp
  const [displayMenu, setDisplayMenu] = useState(false)

  return(
    <div className="modules-modal-controls">
      <div className="modules-modal-controls-header">
        <div className="modules-modal-controls-header-selector">
          <div
            className="modules-modal-controls-header-selector-button"
            alt="Open Documents Control"
            onClick={()=>{
              setDisplayMenu(!displayMenu)
            }}
          >
            {displayMenu?(<HamburgerIconClose/>):(<HamburgerIcon/>)}
          </div>
          {
            displayMenu && (
              <div
                className="modules-modal-controls-header-selector-menu-container"
                onClick={(e)=>{
                  setDisplayMenu(false)
                  e.stopPropagation()
                }}
              >
                <div className="modules-modal-controls-header-selector-menu">
                {defaultApp.vehicleModules.map((module,index)=>{
                  return(
                    <div
                      className="modules-modal-controls-header-selector-menu-item"
                      onClick={()=>{
                        showModalConnect(MODAL_TYPES._MODULE,{module, index})
                        e.stopPropagation()
                      }}
                    >
                      {module.label}
                    </div>
                  )
                })}
                </div>
              </div>
            )
          }
        </div>
        <div className="modules-modal-controls-header-title">
          {vehicleModules[index].label}
        </div>
        <div className="modules-modal-controls-header-close">
          <div className="modules-modal-controls-header-close-button" alt="Close Modal" onClick={()=>{closeModalConnect()}}>
            <CloseIcon/>
          </div>
        </div>
      </div>
      <div className="modules-modal-controls-slider-container">
        <div className="modules-modal-controls-slider">
          <div
            className="modules-modal-controls-slider-prev"
            onClick={()=>{handlePrevClick(index,defaultApp.vehicleModules,showModalConnect)}}
          >
            <ArrowLeft/>
          </div>
          <div
            className="modules-modal-controls-slider-next"
            onClick={()=>{handleNextClick(index,defaultApp.vehicleModules,showModalConnect)}}
          >
            <ArrowRight/>
          </div>
        </div>
      </div>
    </div>
  )
}


function handleNextClick(index, modules, showModalConnect){
  if(index === modules.length-1){
    showModalConnect(MODAL_TYPES._MODULE,{module:modules[0], index:0})

  }
  else{
    showModalConnect(MODAL_TYPES._MODULE,{ module:modules[index+1], index:index+1})
  }
}

function handlePrevClick( index, modules, showModalConnect){
  if(index=== 0){
    showModalConnect(MODAL_TYPES._MODULE,{module:modules[modules.length-1], index:modules.length-1})

  }
  else{
    showModalConnect(MODAL_TYPES._MODULE,{module:modules[index-1], index:index-1})
  }
}
const mapStateToProps = state => ({
  defaultApp: state.defaultApp,
})
const mapDispatchToProps={
  showModalConnect:DEFAULT_APP_UTILS.showModal,
  closeModalConnect: DEFAULT_APP_UTILS.closeModal,
  getDjapiHostedModuleConnect: DEFAULT_APP_UTILS.getDjapiHostedModule,
}
export default connect(mapStateToProps,mapDispatchToProps) (ModulesModal)
