import React from 'react'
import './defaultFooter.scss'
import packageJson from '../../../package.json'

function DefaultFooter(){
  return(
    <footer className="default-footer">
      {packageJson.version}
    </footer>
  )
}
export default DefaultFooter
