import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom'
import { BrowserRouter as Router, browserHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import DefaultAppContainer from '../default-app/defaultAppContainer'
import ModuleQVAppContainer from '../module-quick-view-app/moduleQVAppContainer'

function AppContainer(props){
  let{urlInfo} = props
  return(
    <Router>
      {getSite(urlInfo)}
    </Router>
  )
}

function getSite(urlInfo){
  let{ MQV } = urlInfo.params
  if(MQV){
    return(
      <ModuleQVAppContainer/>
    )
  }
    return(
      <DefaultAppContainer/>
    )
}
const mapStateToProps = state => ({
  urlInfo:state.urlInfo
})
export default connect(mapStateToProps)(AppContainer)
