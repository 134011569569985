export default () =>{
  const queryString = window.location.search;
  const searchParams = new URLSearchParams(queryString);
  let urlParams = {}
  for(var pair of searchParams.entries()) {
    urlParams[pair[0]]= pair[1]
  }
  let pathSegments = window.location.pathname.split('/').filter(i=>i!="")
  let uuid = pathSegments.length? validateUUID(pathSegments[0]): {}

  return{
    hostname:window.location.hostname,
    pathname:window.location.pathname,
    params:urlParams,
    pathSegments,
    ...uuid,
  }
}

function validateUUID(query){
  let uuid = null
  if(query.length ===35){
    uuid={packet_share_uuid:query}
  }
  else{
    uuid={vin:query}
  }

  return uuid
}
