import React,{useState} from 'react'
import './styles/confirmAvailabilityModal.scss'
import { connect } from 'react-redux'
import {updateConfirmAvailabilityForm} from '../defaultAppUtils'
import validateEmail from '../../../utils/validateEmail'
import InputMask from 'react-input-mask';
import DEFAULT_APP_UTILS from '../defaultAppUtils'

function ConfirmAvailabilityModal(props){
  let {confirmAvailabilityForm, vehiclePage, connectUpdateConfirmAvailabilityForm,uuid} = props
  let {first_name, last_name, email, phone_number, message} = confirmAvailabilityForm
  const [attemptedSubmit,setAttemptedSubmit] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  let validateForm = () =>{
    return( first_name.length && validateEmail(email))
  }
  let submit = () =>{
    setAttemptedSubmit(true)
    if(validateForm()){
      setSubmitted(true)
      DEFAULT_APP_UTILS.submitConfirmAvailability(
        uuid,
        {
          first_name,
          last_name,
          email,
          phone_number,
          message,
        }
      )
    }
  }
  if(submitted){
    return(
      <div className="confirm-availability-modal">
        <div className="confirm-availability-success">
          Thank you for contacting us!
          <br/>
          We are confirming this vehicle's availability and a dealer representative will contact you shortly.
        </div>
      </div>
    )
  }
  return(
    <div className="confirm-availability-modal">
      <div className="confirm-availability-header">
        CONFIRM AVAILABILITY OF
        <br/>
        <span>
            {`${vehiclePage.year} ${vehiclePage.make.toUpperCase()} ${vehiclePage.model.toUpperCase()}`}
        </span>
      </div>
      <div className="confirm-availability-form-container">
        <form onSubmit={(e)=>{
          e.preventDefault()
          submit()
        }}
          className="confirm-availability-form"
        >
          <div className={`confirm-availability-form-first-name ${attemptedSubmit&&!first_name.length?'confirm-availability-form-issue':'confirm-availability-form-required'}`} >
            <label hidden for="confirm-availability-first-name">First Name</label>

            <input
              alt="First Name Required"
              id="confirm-availability-first-name"
              value={first_name}
              placeholder="First Name*"
              onChange={e => {
                  connectUpdateConfirmAvailabilityForm(
                    {
                      ...confirmAvailabilityForm,
                      first_name: e.target.value
                    }
                  )
              }}
            />
          </div>
          <div className="confirm-availability-form-last-name">
            <label hidden for="confirm-availability-last-name">Last Name</label>

            <input
              id="confirm-availability-last-name"
              value={last_name}
              placeholder="Last Name"
              onChange={e => {
                  connectUpdateConfirmAvailabilityForm(
                    {
                      ...confirmAvailabilityForm,
                      last_name: e.target.value
                    }
                  )
              }}
            />
          </div>
          <div className={`confirm-availability-form-first-name ${attemptedSubmit&&!validateEmail(email)?'confirm-availability-form-issue':'confirm-availability-form-required'}`}>
            <label hidden for="confirm-availability-email">Email</label>

            <input
              alt="Email Required"
              id="confirm-availability-email"
              value={email}
              placeholder="Email*"
              onChange={e => {
                  connectUpdateConfirmAvailabilityForm(
                    {
                      ...confirmAvailabilityForm,
                      email: e.target.value
                    }
                  )
              }}
            />
          </div>
          <div className="confirm-availability-form-phone-number">
            <label hidden for="confirm-availability-phone-number">Phone Number</label>

            <InputMask
              id="confirm-availability-phone-number"
              value={phone_number}
              placeholder="(555) 555-5555"
              mask={'(999) 999-9999'}
              onChange={e => {
                  connectUpdateConfirmAvailabilityForm(
                    {
                      ...confirmAvailabilityForm,
                      phone_number: e.target.value
                    }
                  )
              }}
            />
          </div>
          <div className="confirm-availability-form-message">
            <label hidden for="confirm-availability-message">Message</label>

            <textarea
              id="confirm-availability-message"
              value={message}
              placeholder="Add message"
              onChange={e => {
                  connectUpdateConfirmAvailabilityForm(
                    {
                      ...confirmAvailabilityForm,
                      message: e.target.value
                    }
                  )
              }}
            />
          </div>
          <div className="confirm-availability-form-submit">
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  )
}
const mapStateToProps = state =>({
  vehiclePage: state.defaultApp.vehiclePage,
  confirmAvailabilityForm: state.defaultApp.confirmAvailabilityForm,
  uuid: {
    vin: state.urlInfo.vin,
    packet_share_uuid: state.urlInfo.packet_share_uuid
  },
})
const mapDispatchToProps = {
  connectUpdateConfirmAvailabilityForm:updateConfirmAvailabilityForm,
}
export default connect(mapStateToProps,mapDispatchToProps)(ConfirmAvailabilityModal)
