import React, { useEffect, useState, useRef } from 'react'
import './styles/moduleHtml5Video.scss'

function ModuleHtml5Video(props){
  let{ module } = props
  const videoRef = useRef(null)
  useEffect(()=>{
    return function stopVideoPlay(){
      const video = document.querySelector(".module-html5-video-player");
      video.pause();
      video.currentTime = 0;
    }
  })
  return (
    <div className="module-html5-video">
      <video
          className={"module-html5-video-player"}
          controls
          ref={videoRef}
        >
          <source src={module.url} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
    </div>)
}
export default ModuleHtml5Video
