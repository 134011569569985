import React from 'react'

const IPacketIcon = props => {
  const style = '.a{font-size:64.92px;letter-spacing:-0.03em;}.a,.e{font-family:AvenirNext-Medium, Avenir Next;font-weight:500;}.b{letter-spacing:-0.08em;}.c{letter-spacing:-0.04em;}.d{letter-spacing:-0.03em;}.e{font-size:51px;letter-spacing:-0.02em;}'
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 342.12 104.47">
      <defs>
        <style>
          {style}
        </style>
      </defs>
      <text className="a" transform="translate(114.18 74.01)">i<tspan className="b" x="15.19" y="0">P</tspan><tspan x="48.56" y="0">ac</tspan><tspan className="c" x="112.57" y="0">k</tspan><tspan className="d" x="143.99" y="0">et</tspan></text><text className="e" transform="translate(313.48 57.03) scale(0.58)">™</text>
      <path d="M52.24,5A47.24,47.24,0,1,1,5,52.24,47.29,47.29,0,0,1,52.24,5m0-5a52.24,52.24,0,1,0,52.23,52.24A52.24,52.24,0,0,0,52.24,0Z"/>
      <path d="M79.16,74.29H25.08A1.35,1.35,0,0,1,23.72,73l-1.8-34.92a1.36,1.36,0,0,1,1.36-1.43H81.33a1.36,1.36,0,0,1,1.36,1.45L80.52,73A1.36,1.36,0,0,1,79.16,74.29Z"/>
      <path d="M79.78,38.29V36a1.16,1.16,0,0,0-1.16-1.17H49.15a1.28,1.28,0,0,1-1.28-1.28V31.71a1.34,1.34,0,0,0-1.35-1.35H27.83a1.34,1.34,0,0,0-1.35,1.35v1.87a1.28,1.28,0,0,1-1.28,1.28h-.27A1.17,1.17,0,0,0,23.76,36L24,68C34.82,50.28,55.74,38.29,79.78,38.29Z"/>
    </svg>
  )
}
export default IPacketIcon
