import AIP_DJAPI,{AIP_DJAPI_URLS} from '../../api/aipDjapi.js'
import ACTIONS from '../../state/constants/reducerConstants'
import io from "socket.io-client";
import { version } from '../../../package.json'



let etsSocket = null;

export function getVehicleModule(vin,moduleId){
  return (dispatch) =>{
    let url = AIP_DJAPI_URLS.VEHICLE_MODULES+"?vin="+vin
    AIP_DJAPI(url).then((response)=>{
      return response.json()
    }).then((res)=>{
      if(res && res.modules){
        let foundModule = res.modules.find((module)=>{
          return module.module_id === parseInt(moduleId)
        })
        if(foundModule && foundModule.djapi_hosted){
          AIP_DJAPI(foundModule.url).then((secondaryResponse)=>{
            console.log(secondaryResponse)
            return secondaryResponse.json()
          }).then((secondaryRes)=>{
            if(secondaryRes){
              console.log(secondaryRes)
              foundModule.payload = secondaryRes
              dispatch({
                type: ACTIONS.GET_VEHICLE_MODULE_QV,
                module: foundModule
              })
            }
          })
        }
        else if(foundModule){
          dispatch({
            type: ACTIONS.GET_VEHICLE_MODULE_QV,
            module: foundModule
          })
        }
        else{
          console.log("A Problem has Occured")
        }
      }
    })
  }
}
export function initializeEtsSocket(vin, enhanced_webicon_id,module_name){
  let vehiclePageUrl = AIP_DJAPI_URLS.VEHICLE_PAGE +"?vin="+vin
  AIP_DJAPI(vehiclePageUrl).then((response)=>{
    return response.json()
  }).then((res)=>{
    if(res && res.vehicle_id){
      let socketUrl = "https://ets.autoipacket.com/vdp"
      etsSocket = io('https://ets.autoipacket.com/vdp',{ transports: ['websocket'] });
      etsSocket.on("connect",()=>{
        emitPageInfo(vin, res.vehicle_id);
        emitSessionInfo(enhanced_webicon_id);
        emitModuleClick(module_name);
      });
    }
  })

}
function emitPageInfo(vin, vehicle_id){
  let pageInfo = {
    vehicle_id,
    vin,
    enhanced_webicon_mode: true,
  }


  etsSocket.emit("vdp data", pageInfo)
}
function emitSessionInfo(enhanced_webicon_id){
  let sessionInfo = {
    win_wd: window.innerWidth,
    win_ht: window.innerHeight,
    user_agent: navigator.userAgent,
    baseURL: window.location.host,
    fullURL: window.location.href,
    referrer: document.referrer ? document.referrer : "",
    device:"Desktop",
    query_string: window.location.search,
    paramURL: window.location.pathname,
    VDPVersion: `react-autoipacket-vdp:${version}`,
    enhanced_webicon_id,
  }
  etsSocket.emit("session data", sessionInfo)
}
function emitModuleClick(module_name){
  etsSocket.emit("module action",{
    action:"click",
    legacy:`MC:${module_name}`,
  })
}
const MODULE_QV_UTILS={
  getVehicleModule,
  initializeEtsSocket,
}
export default MODULE_QV_UTILS
