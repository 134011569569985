import React,{useEffect, useState} from 'react'
import './vehiclePage.scss'
import ModalContainer from '../modals/modalContainer'
import MODAL_TYPES from '../modals/modalTypes'
import VehiclePageSlider from './vehiclePageSlider'
import ExpandFullscreen from '../../common/common-assets/icons/expandFullscreen'
import formatPhoneNumber from '../../../utils/formatPhoneNumber'
import IPacketSimpleIcon from '../../common/common-assets/icons/iPacketSimpleIcon'
function VehiclePage(props){
  let {vehiclePage,vehicleModules,vehicleImages, showModalConnect} = props
  useEffect(()=>{
    if(vehiclePage){
      document.title=`${vehiclePage.year} ${vehiclePage.make} ${vehiclePage.model} | AutoiPacket`
    }
  },[vehiclePage])
  if(vehiclePage && vehicleModules && vehicleImages && showModalConnect){
    return(
      <div className="vdp-container">
        <div className="vdp">
          {renderVehicleHeading(vehiclePage)}
          <VehiclePageSlider {...props}/>
          {renderVehicleInfo(vehiclePage,vehicleModules,showModalConnect)}
          {renderVehicleModules(vehicleModules,showModalConnect)}
          <VehiclePageMap store={vehiclePage.store} />
          <ModalContainer/>
        </div>
      </div>
    )
  }
  return(<div>...Loading</div>)
}

function renderVehicleInfo(vehiclePage,vehicleModules,showModalConnect){
  if(vehiclePage && vehicleModules){
    return(
      <div className="vdp-info-container">
        <div className="vdp-info">
          <div className="vdp-info-price">
            {`Price: `}
            <span>
              {`$${vehiclePage.price.toLocaleString()}`}
            </span>

          </div>
          <div className="vdp-info-misc">

            <div className="vdp-info-misc-item">
              <h1>STOCK NO</h1>
              {vehiclePage.stock_no?vehiclePage.stock_no:"N/A"}
            </div>

            <div className="vdp-info-misc-item">
              <h1>ENGINE</h1>
              {vehiclePage.engine_descrip?vehiclePage.engine_descrip:"N/A"}
            </div>

            <div className="vdp-info-misc-item">
              <h1>TRANS</h1>
              {vehiclePage.trans_descrip?vehiclePage.trans_descrip:"N/A"}
            </div>

            <div className="vdp-info-misc-item">
              <h1>BODY TYPE</h1>
              {vehiclePage.body_type?vehiclePage.body_type:"N/A"}
            </div>

            <div className="vdp-info-misc-item">
              <h1>COLOR</h1>
              {vehiclePage.ext_color?vehiclePage.ext_color:"N/A"}
            </div>

            <div className="vdp-info-misc-item">
              <h1>INTERIOR</h1>
              {vehiclePage.int_color?vehiclePage.int_color:"N/A"}
            </div>
          </div>
          {
            vehiclePage.seller_notes?
            (
              <div className="vdp-info-seller-notes">
                <h1>Seller Notes:</h1>
                {vehiclePage.seller_notes}
              </div>
            ):null
          }
        </div>
      </div>
    )
  }
  return(<React.Fragment/>)
}
function renderVehicleHeading(vehiclePage){
  let today = new Date()
  let vehicleInDate = new Date(vehiclePage.in_dt)
  let daysInInventory = Math.floor(Math.floor(today - vehicleInDate) / (1000 * 60 * 60 * 24))
  let vehiclePrefix = (
    <span className="vdp-heading-stock-type vdp-heading-new">New</span>
  )
  if(vehiclePage.cpo){
    vehiclePrefix = (
      <span className="vdp-heading-stock-type vdp-heading-cpo">Certified Pre-Owned</span>
    )
  }
  else if(vehiclePage.stock_type==="U"){
    vehiclePrefix = (
      <span className="vdp-heading-stock-type vdp-heading-po">Pre-Owned</span>
    )
  }
  if(vehiclePage){
    return(
      <div className="vdp-heading">
        <h1>
          {vehiclePrefix}
          {` ${vehiclePage.year} ${vehiclePage.make} ${vehiclePage.model}`}
        </h1>
        <h2>
          {`VIN: ${vehiclePage.vin} | Miles: `}
          <span className="vdp-heading-mileage">{vehiclePage.mileage.toLocaleString()}</span>
        </h2>
        <div className="vdp-heading-watermark">
          <a href="https://www.ipacket.us/">
            {`Presentation powered by `}
            <IPacketSimpleIcon/>
            {` iPacket${'\u2122'}(v.${daysInInventory})`}
          </a>
        </div>
      </div>
    )
  }
}
function renderVehicleModules(vehicleModules,showModalConnect){

  if(vehicleModules && vehicleModules.length>2){
    // let modules = vehicleModules.slice(2, vehicleModules.length-1)
    return(
      <div className="vdp-modules">
        {vehicleModules.map((module,i)=>{
          return(
            <React.Fragment key={i}>
              {renderModule(module,showModalConnect,i,(i*50)+200)}
            </React.Fragment>
          )
        })}
      </div>
    )
  }
}
function renderModule(module,showModalConnect,index,animationDelay){

  if(module && showModalConnect){
    return(
      <div className="vdp-module-container" name={module.module_name}>
        <div className="vdp-module"
          onClick={()=>{
          showModalConnect(MODAL_TYPES._MODULE,{module, index})
        }}>
        <div className="vdp-module-overlay">
          <div className="vdp-module-overlay-icon">
            <ExpandFullscreen/>
          </div>
        </div>
        <div className="vdp-module-thumbnail" style={{ backgroundImage: `url(${module.content_preview_img? module.content_preview_img: module.thumb_img})`, backgroundSize: 'cover' }}></div>
        </div>
        <div className="vdp-module-label">
          {module.label}
        </div>
      </div>
    )
  }
}
function VehiclePageMap(props){
  let {store} = props
  const [mapVisible, toggleMap] = useState(true)
  let formattedPhoneNumber = formatPhoneNumber(store.phone_number)

  if(store){
    return(
      <div className="vdp-map-container">
        {mapVisible?
          (
            <div className="vdp-map">
              <object className="vdp-map-iframe" data={store.google_map_iframe}>
                <img className="vdp-map-img" src={store.google_map_image_link} alt={store.name} onError={()=>{toggleMap(false)}}/>
              </object>
            </div>
          ):null
        }
        <div className="vdp-map-store-info">
          <span className="vdp-map-store-name">
            {`${store.full_name}`}
          </span>
          <br/>
          {store.street}
          <br/>
          {`${store.city}, ${store.state} ${store.zip_code}`}
          <br/>
          {formatPhoneNumber(store.phone_number)}
          <br/>
        </div>
      </div>
    )
  }
}
export default VehiclePage
