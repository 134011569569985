import React from 'react'
import {connect} from 'react-redux'

function DefaultAppSkin(props){
  const { vehiclePage, skin} = props
  if(skin && vehiclePage){
    const {
      color_accent_primary,
      color_nav_primary,
      color_nav_secondary,
      color_nav_btn_active_back,
      color_nav_btn_active_font,
      color_body_inner_backgrnd,
      color_body_outer_backgrnd,
      color_card_new_txt,
      color_card_cpo_txt,
      color_card_po_txt,
      color_packets_text,
      color_car_price,
      color_i_icon,
      color_footer_button,
      color_footer_backgrnd,
    } = skin


    let skin_styles = {}
    if(color_accent_primary){
      skin_styles.color_accent_primary_style=`
        .vdp-heading-stock-type{color:${color_accent_primary};}
        .vdp-heading h2 .vdp-heading-mileage{color:${color_accent_primary};}
        .vdp-info .vdp-info-price{color:${color_accent_primary};}
      `
    }
    if(color_nav_primary){
      skin_styles.color_nav_primary_style=`
        header {color:${color_nav_primary};}
        header .header-center-section .header-center-section-static ul svg{fill:${color_nav_primary};}
        header .header-logo{
          fill: ${color_nav_primary};
        }
      `
    }
    if(color_nav_secondary){
      skin_styles.color_nav_secondary_style=`
        header{background-color:${color_nav_secondary};}
        header .header-center-section .header-center-section-static ul li:hover{color:${color_nav_secondary};}
        header .header-center-section .header-center-section-static ul li:hover svg{fill:${color_nav_secondary};}
      `
    }
    if(color_nav_btn_active_back){
      skin_styles.color_nav_btn_active_back_style=`
        header .header-center-section .header-center-section-static ul li:hover{color:${color_nav_btn_active_back};}
      `
    }
    else if(color_nav_btn_active_back && !color_nav_btn_active_font && !color_nav_primary){
      skin_styles.color_nav_btn_active_font_style=`
        header .header-center-section .header-center-section-static ul li:hover{color:black;}
        header .header-center-section .header-center-section-static ul li:hover svg{fill:black;}
      `
    }
    if(color_nav_btn_active_font){
      skin_styles.color_nav_btn_active_font_style=`
        header .header-center-section .header-center-section-static ul li:hover{color:${color_nav_btn_active_font};}
        header .header-center-section .header-center-section-static ul li:hover svg{fill:${color_nav_btn_active_font};}
      `
    }



    let skinStyleString = ''
    Object.keys(skin_styles).map(style =>{
      skinStyleString += skin_styles[style]
    })
    return(
      <React.Fragment>
        {props.children}
        <style>
          {skinStyleString}
        </style>
      </React.Fragment>
    )
  }
  return(
    <React.Fragment>
      {props.children}
    </React.Fragment>
  )
}
const mapStateToProps = state=>({
  ...state.defaultApp,
})
export default connect(mapStateToProps)(DefaultAppSkin)
