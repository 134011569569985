import React,{useEffect} from 'react'
import MODAL_TYPES from './modalTypes'
import ModulesModal from './modulesModal'
import DEFAULT_APP_UTILS from '../defaultAppUtils'
import ConfirmAvailabilityModal from './confirmAvailabilityModal'
import ZoomMeetingRequestModal from './zoomMeetingRequestModal'
import VehiclePageSliderModal from './vehiclePageSliderModal'
import {connect} from 'react-redux'
import './styles/modalContainer.scss'

function ModalContainer(props){
  let { selectedModal, closeModalConnect }= props

  let escModal = (e) =>{
    if(e.keyCode === 27) {
      closeModalConnect()
    }
  }
  useEffect(()=>{
    if(selectedModal){

      document.addEventListener("keyup", escModal,false)
      return function(){
        document.removeEventListener("keyup", escModal);

      }
    }
  },[selectedModal])
  if(selectedModal){
    return(
      <div className="modal-container" onClick={(e)=>{
        if (e.target.className === 'modal-container' || e.target.className === 'modal-overlay') {
          closeModalConnect()
        }
      }}>
        {getModal(selectedModal)}
      </div>
    )
  }
  return(null)
}
function getModal(selectedModal){
  switch(selectedModal.type){
    case MODAL_TYPES._MODULE:
      return(
        <ModulesModal {...selectedModal.params}/>
      )
    case MODAL_TYPES._CONFIRM_AVAILABILITY:
      return(
        <ConfirmAvailabilityModal {...selectedModal.params}/>
      )
    case MODAL_TYPES._ZOOM_MEETING_REQUEST:
      return(
        <ZoomMeetingRequestModal {...selectedModal.params}/>
      )
    case MODAL_TYPES._VEHICLE_PAGE_SLIDER:
      return(
        <VehiclePageSliderModal {...selectedModal.params}/>
      )
  }
}
const mapStateToProps = state =>({
  selectedModal: state.defaultApp.selectedModal,
})
const mapDispatchToProps = {
  closeModalConnect: DEFAULT_APP_UTILS.closeModal
}
export default connect(mapStateToProps,mapDispatchToProps)(ModalContainer)
