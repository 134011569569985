import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import './styles/glyphicon.css'
import AppContainer from './apps/app-container/appContainer.js'
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, browserHistory } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import REDUCER_CONSTANTS from './state/constants/reducerConstants'
import configureStore from './state/configureStore'
import parseURL from "./utils/parseURL"
export const {store, persistor} = configureStore()
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { version } from '../package.json'


function getURLInfo(){
  store.dispatch({
    type: REDUCER_CONSTANTS.GET_URL_INFO,
    urlInfo:parseURL()
  })
}
getURLInfo()

Sentry.init({
  dsn: "https://ecf457a450e14b21a199b262c24921af@o141269.ingest.sentry.io/6056654",
  release: version,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0,
});



ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
        <AppContainer/>
    </PersistGate>
  </Provider>
  ,
  document.getElementById('react-autoipacket-vdp')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
