import REDUCER_CONSTANTS from '../constants/reducerConstants'
const initialState = {
  v3Migrated:false,
  zoomMeetingRequestForm:{
    first_name: "",
    last_name: "",
    email:"",
    phone_number:"",
    message:"",
    submitted: false
  },
  confirmAvailabilityForm:{
    first_name: "",
    last_name: "",
    email:"",
    phone_number:"",
    message:"",
    submitted: false
  },
}

function defaultAppReducer(state=initialState, action){
  let{
    CHECK_V3_MIGRATED,
    GET_VEHICLE_PAGE,
    GET_VEHICLE_MODULES,
    GET_VEHICLE_IMAGES,
    GET_SALESPERSON_INFO,
    GET_PAYMENT_CALCULATOR,
    SHOW_MODAL,
    CLOSE_MODAL,
    UPDATE_ZOOM_MEETING_REQUEST_FORM,
    UPDATE_CONFIRM_AVAILABILITY_FORM,
    GET_SITE_SKIN,
    GET_SITE_CONFIG,
  } = REDUCER_CONSTANTS
  //Put all action declarations in here
  let{first_name,last_name,email,phone_number,message, submitted} = action

  switch(action.type){
    case CHECK_V3_MIGRATED:
      return{
        ...state,
        v3Migrated:action.v3Migrated,
        v3MigratedChecked:true,
      }
    case GET_VEHICLE_PAGE:
      return{
        ...state,
        vehiclePage: {...action.vehiclePage},
        zoomMeetingRequestForm:{
          ...state.zoomMeetingRequestForm,
          ...action.vehiclePage.customer,
        },
        confirmAvailabilityForm:{
          ...state.confirmAvailabilityForm,
          ...action.vehiclePage.customer,
        }
      }
    case GET_VEHICLE_MODULES:
      return{
        ...state,
        vehicleModules: [...action.vehicleModules]
      }
    case GET_VEHICLE_IMAGES:
      return{
        ...state,
        vehicleImages: [...action.vehicleImages]
      }
    case GET_SALESPERSON_INFO:
      return{
        ...state,
        salespersonInfo: {...action.salespersonInfo}
      }
    case SHOW_MODAL:
      return{
        ...state,
        selectedModal: {...action.selectedModal}
      }
    case CLOSE_MODAL:
      return{
        ...state,
        selectedModal: null
      }
    case GET_PAYMENT_CALCULATOR:
      return{
        ...state
      }
    case GET_SITE_SKIN:
      return {
        ...state,
        skin:{...action.skin}
      }
    case GET_SITE_CONFIG:
      return {
        ...state,
        config:{...action.config}
      }
    case UPDATE_ZOOM_MEETING_REQUEST_FORM:
      return{
        ...state,
        zoomMeetingRequestForm:{
          ...state.zoomMeetingRequestForm,
          first_name,
          last_name,
          email,
          phone_number,
          message,
          submitted,
        },
        confirmAvailabilityForm:{
          ...state.confirmAvailabilityForm,
          first_name,
          last_name,
          email,
          phone_number,
          message,
        }
      }
      return{
        ...state
      }
    case UPDATE_CONFIRM_AVAILABILITY_FORM:
      return{
        ...state,
        confirmAvailabilityForm:{
          ...state.confirmAvailabilityForm,
          first_name,
          last_name,
          email,
          phone_number,
          message,
          submitted,
        },
        zoomMeetingRequestForm:{
          ...state.zoomMeetingRequestForm,
          first_name,
          last_name,
          email,
          phone_number,
          message,
        },
      }
    default:
      return state
  }
}
export default defaultAppReducer
