import React,{useState} from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import ArrowRight from '../../common/common-assets/icons/arrowRight'
import ArrowLeft from '../../common/common-assets/icons/arrowLeft'
import ExpandFullscreen from '../../common/common-assets/icons/expandFullscreen'
import MODAL_TYPES from '../modals/modalTypes'

function VehiclePageSlider(props){
  let {vehicleImages, selectedModal,showModalConnect} = props
  const [index,setIndex] = useState(0)
  return(
    <div className="vdp-slider-container">
        <div className={`${"vdp-slider"}`}>
          <div className="vdp-slider-overlay">
            <div className="vdp-slider-control-expand" onClick={()=>{showModalConnect(MODAL_TYPES._VEHICLE_PAGE_SLIDER,{vehicleImages})}}>
              <div className="vdp-slider-control-expand-icon">
                <ExpandFullscreen/>
              </div>
            </div>
            <div
              className="vdp-slider-control-prev"
              onClick={()=>{handlePrevClick(setIndex,index,vehicleImages)}}
            >
              <ArrowLeft/>
            </div>
            <div
              className="vdp-slider-control-next"
              onClick={()=>{handleNextClick(setIndex,index,vehicleImages)}}
            >
              <ArrowRight/>
            </div>
          </div>
          <SliderImage src={vehicleImages[index]} index={index}/>
        </div>
    </div>
  )
}
function SliderImage(props){
  let{vehiclePage} = props
  return(
    <TransitionGroup
      className="vdp-slider-image-container"
    >
      <CSSTransition key={props.src} classNames="vdp-slider-image-transition" timeout={1000}>
        <div className="vdp-slider-image"><img src={props.src} alt={`Vehicle Image ${props.index}`}/></div>
      </CSSTransition>
    </TransitionGroup>
  )
}
function handleNextClick(setIndex,index,vehicleImages){
  if(index=== vehicleImages.length-1){
    index = 0
  }
  else{
    index+=1
  }
  setIndex(index)
}
function handlePrevClick(setIndex,index,vehicleImages){
  if(index=== 0){
    index = vehicleImages.length-1
  }
  else{
    index-=1
  }
  setIndex(index)
}
export default VehiclePageSlider
