import React from 'react'
import './styles/module3PA.scss'

function Module3PA(props){
  let{module,redirect} = props
  if(redirect){
    window.location.href=module.url;
  }
  return (
    <div className="module-3pa">
      <a href={module.url} target="_blank">
      </a>
    </div>
  )
}
export default Module3PA
