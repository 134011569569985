import React, { useState, useEffect } from 'react'
import DefaultApp from './defaultApp'
import DefaultAppSkin from './defaultAppSkin'
import { connect } from 'react-redux'
import DEFAULT_APP_UTILS from './defaultAppUtils'

function DefaultAppContainer(props){
 let{vehiclePage,vehicleModules,vehicleImages,uuid} = props
  useEffect(()=>{
    //TODO Clean this up and fix vin/uuid
    if(!props.v3MigratedChecked){
      props.checkV3MigratedConnect(uuid)
    }
    if(props.v3Migrated && props.v3MigratedChecked){
      DEFAULT_APP_UTILS.vdpTracking(uuid)
      if(!props.vehiclePage){
        props.getVehiclePageConnect(uuid)
      }
      if(!props.vehicleModules){
        props.getVehicleModulesConnect(uuid)
      }
      if(!props.vehicleImages){
        props.getVehicleImagesConnect(uuid)
      }
      if(!props.salespersonInfo){
        props.getSalespersonInfoConnect(uuid)
      }
      if(!props.siteSkin){
        props.getSiteSkinConnect(uuid)
      }
      if(!props.siteConfig){
        props.getSiteConfigConnect(uuid)
      }

    }
    document.title=`Loading..`
  },[props.v3Migrated])

  return(
    <DefaultAppSkin>
      <DefaultApp/>
    </DefaultAppSkin>
  )
}

const mapStateToProps = state=>({
  uuid: {
    vin:state.urlInfo.vin,
    packet_share_uuid: state.urlInfo.packet_share_uuid,
  },
  ...state.defaultApp,

})
const mapDispatchToProps = {
    checkV3MigratedConnect:DEFAULT_APP_UTILS.checkV3Migrated,
    getVehiclePageConnect:DEFAULT_APP_UTILS.getVehiclePage,
    getVehicleModulesConnect:DEFAULT_APP_UTILS.getVehicleModules,
    getVehicleImagesConnect:DEFAULT_APP_UTILS.getVehicleImages,
    getSalespersonInfoConnect:DEFAULT_APP_UTILS.getSalespersonInfo,
    getSiteSkinConnect:DEFAULT_APP_UTILS.getSiteSkin,
    getSiteConfigConnect: DEFAULT_APP_UTILS.getSiteConfig,
}
export default connect(mapStateToProps,mapDispatchToProps) (DefaultAppContainer)
