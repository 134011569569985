import React from 'react'

const IPacketSimpleIcon = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 104.5 104.5">
      <path d="M52.2,5c26.1,0,47.2,21.2,47.2,47.2S78.3,99.5,52.2,99.5S5,78.3,5,52.2C5,26.2,26.2,5,52.2,5 M52.2,0C23.4,0,0,23.4,0,52.2  s23.4,52.2,52.2,52.3s52.2-23.4,52.3-52.2c0,0,0,0,0,0C104.5,23.4,81.1,0,52.2,0z"/>
      <path d="M79.2,74.3H25.1c-0.7,0-1.3-0.6-1.4-1.3l-1.8-34.9c0-0.8,0.5-1.4,1.3-1.4c0,0,0,0,0.1,0h58.1c0.8,0,1.4,0.6,1.4,1.4  c0,0,0,0.1,0,0.1L80.5,73C80.5,73.7,79.9,74.3,79.2,74.3z"/>
      <path d="M79.8,38.3V36c0-0.6-0.5-1.2-1.1-1.2c0,0,0,0,0,0H49.2c-0.7,0-1.3-0.6-1.3-1.3v-1.8c0-0.7-0.6-1.3-1.3-1.4c0,0,0,0,0,0H27.8  c-0.7,0-1.3,0.6-1.4,1.3c0,0,0,0,0,0v1.9c0,0.7-0.6,1.3-1.3,1.3h-0.3c-0.6,0-1.2,0.5-1.2,1.1L24,68C34.8,50.3,55.7,38.3,79.8,38.3z"/>
    </svg>
  )
}
export default IPacketSimpleIcon
