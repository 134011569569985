import React from 'react'
import PrintIcon from '../common/common-assets/icons/printicon'
import CheckIcon from '../common/common-assets/icons/checkicon'
import VideoCameraIcon from '../common/common-assets/icons/videocameraicon'
import MODAL_TYPES from './modals/modalTypes'

function DefaultNavLinks(props){
  let{showModalConnect} = props
  return(
    <ul className="header-navlinks">
      <li className="header-navlinks-print">
        <PrintIcon color={props.color}/>
        <br/>
        PRINT
        <br/>
        PACKET
      </li>
      <li className="header-navlinks-confirm-availability" onClick={()=>{showModalConnect(MODAL_TYPES._CONFIRM_AVAILABILITY)}}>
        <CheckIcon color={props.color}/>
        <br/>
        CONFIRM
        <br/>
        AVAILABILITY
      </li>
      <li className="header-navlinks-zoom-meeting" onClick={()=>{showModalConnect(MODAL_TYPES._ZOOM_MEETING_REQUEST)}}>
        <VideoCameraIcon color={props.color}/>
        <br/>
        ZOOM
        <br/>
        MEETING
      </li>
    </ul>
  )
}

export default DefaultNavLinks
