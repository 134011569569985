import React from 'react'

function ArrowRight(props){
  let color = props.color
  if(!color){
    color="black"
  }
  return(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M0 0h24v24H0V0z" fill="none"/>
      <path fill={color} d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"/>
    </svg>
  )
}
export default ArrowRight
