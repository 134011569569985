import React, { useEffect, useState, useRef } from "react"
import DownloadIcon from "../../common-assets/icons/downloadIcon"
import "./styles/moduleImage.scss"

function ModuleImage(props) {
  let { module } = props
  const [scaleIndex, setScaleIndex] = useState(7)
  const [initialImgWidth, setInitialImgWidth] = useState(null)
  const moduleImgElement = useRef(null)
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false)

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase()
    setIsMobileOrTablet(
      /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      )
    )

    if (
      moduleImgElement.current.clientWidth /
        moduleImgElement.current.clientHeight >
      1
    ) {
      setInitialImgWidth(moduleImgElement.current.clientWidth * 0.5)
    } else {
      setInitialImgWidth(moduleImgElement.current.clientWidth)
    }
  }, [])

  const scaleValues = [
    0.25, 0.33, 0.5, 0.67, 0.75, 0.8, 0.9, 1, 1.1, 1.25, 1.5, 1.75, 2, 2.5, 3,
    4, 5
  ]
  const decreaseScale = () => {
    if (scaleIndex > 0) {
      setScaleIndex(scaleIndex - 1)
    }
  }
  const increaseScale = () => {
    if (scaleIndex < scaleValues.length - 1) {
      setScaleIndex(scaleIndex + 1)
    }
  }

  return (
    <div className="module-image" ref={moduleImgElement}>
      <div className="module-viewer-container">
        <div className="module-viewer-header">
          <div className="module-viewer-header-name">{module.label}</div>
          {!isMobileOrTablet ? (<div className="module-viewer-header-tools">
            <button
              onClick={() => {
                decreaseScale()
              }}
              type="image"
              alt="Click to zoom out"
            >
              -
            </button>
            <div>{`${Math.round(scaleValues[scaleIndex] * 100)} %`}</div>
            <button
              onClick={() => {
                increaseScale()
              }}
              type="image"
              alt="Click to zoom in"
            >
              +
            </button>
          </div>) : null}
          <a className="download-icon" href={module.url}>
            <DownloadIcon />
          </a>
        </div>
        <div
          className="module-viewer-content-container"
          id="module-viewer-content-container"
        >
          <img
            src={module.url}
            alt={module.module_name}
            style={{ width: initialImgWidth * scaleValues[scaleIndex] }}
          />
        </div>
      </div>
    </div>
  )
}
export default ModuleImage
