import React from 'react'

function PersonalVideoIcon(props){
  let color = props.color
  if(!color){
    color="black"
  }
  return(
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" >
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M21 3H3c-1.11 0-2 .89-2 2v12c0 1.1.89 2 2 2h5v2h8v-2h5c1.1 0 1.99-.9 1.99-2L23 5c0-1.11-.9-2-2-2zm0 14H3V5h18v12z"/>
    </svg>
  )
}
export default PersonalVideoIcon
