
import AIP_DJAPI,{AIP_DJAPI_URLS} from '../../api/aipDjapi.js'
import ACTIONS from '../../state/constants/reducerConstants'

export function checkV3Migrated(uuid){
  return (dispatch)=>{
    let url = AIP_DJAPI_URLS.V3_MIGRATED
    if(uuid.vin){
      url += `?vin=${uuid.vin}`
    }
    else if(uuid.packet_share_uuid){
      url += `?packet_share_uuid=${uuid.packet_share_uuid}`
    }
    else{
      return
    }
    AIP_DJAPI(url).then((response)=>{
      return response.json()
    }).then((res)=>{
      if(res){
        if(true || res.migrated){
          dispatch({
            type:ACTIONS.CHECK_V3_MIGRATED,
            v3Migrated:true,
            // v3Migrated:res.migrated,
          })
        }
        else{
          window.location.href="http://google.com";
        }
      }
    })

  }
}
function getUuidParam(uuid){

  if(uuid.packet_share_uuid){
    return `packet_share_uuid=${uuid.packet_share_uuid}`
  }
  else if(uuid.vin){
    return `vin=${uuid.vin}`
  }
  return ""
}
export function getVehiclePage(uuid){
  return (dispatch) =>{
    let url = AIP_DJAPI_URLS.VEHICLE_PAGE+"?"+getUuidParam(uuid)
    AIP_DJAPI(url).then((response)=>{
      return response.json()
    }).then((res)=>{
      if(res && res.store){
        dispatch({
          type: ACTIONS.GET_VEHICLE_PAGE,
          vehiclePage: res
        })
      }
    })
  }
}
export function getVehicleModules(uuid){
  return (dispatch) =>{
    let url = AIP_DJAPI_URLS.VEHICLE_MODULES+"?"+getUuidParam(uuid)
    AIP_DJAPI(url).then((response)=>{
      return response.json()
    }).then((res)=>{
      if(res && res.modules){
        dispatch({
          type: ACTIONS.GET_VEHICLE_MODULES,
          vehicleModules: res.modules
        })
      }
    })
  }
}
export function getVehicleImages(uuid){
  return (dispatch) =>{
    let url = AIP_DJAPI_URLS.VEHICLE_IMAGES+"?"+getUuidParam(uuid)
    AIP_DJAPI(url).then((response)=>{
      return response.json()
    }).then((res)=>{
      if(res && res.vehicle_images){
        dispatch({
          type: ACTIONS.GET_VEHICLE_IMAGES,
          vehicleImages: res.vehicle_images
        })
      }
    })
  }
}
export function getSalespersonInfo(uuid){
  return (dispatch) =>{
    let url = AIP_DJAPI_URLS.SALESPERSON_INFO+"?"+getUuidParam(uuid)
    AIP_DJAPI(url).then((response)=>{
      return response.json()
    }).then((res)=>{
      if(res && res.sales_person_name){
        dispatch({
          type: ACTIONS.GET_SALESPERSON_INFO,
          salespersonInfo: res,
        })
      }
    }).catch((e)=>{
      // console.log("No Salesperson Exists")
    })
  }
}
export function showModal(type, params){
  return(dispatch)=>{
    dispatch({
      type:ACTIONS.SHOW_MODAL,
      selectedModal:{
        type,
        params,
      }
    })
  }
}
export function closeModal(){
  return(dispatch)=>{
    dispatch({
      type:ACTIONS.CLOSE_MODAL
    })
  }
}
export function updateZoomMeetingRequestForm(form){
  return(dispatch)=>{
    dispatch({
      type:ACTIONS.UPDATE_ZOOM_MEETING_REQUEST_FORM,
      ...form,
    })
  }
}
export function submitZoomMeetingRequest(uuid,fields){
  let url = AIP_DJAPI_URLS.ZOOM_MEETING_REQUEST+"?"+getUuidParam(uuid)
  const form = new FormData()
  fields.first_name && form.append('first_name', fields.first_name)
  fields.last_name && form.append('last_name', fields.last_name)
  fields.email && form.append('email', fields.email)
  fields.phone_number && form.append('phone_number', fields.phone_number)
  fields.message && form.append('message', fields.message)
  AIP_DJAPI(
    url,{
      method:'PUT',
      body: form,
    }
  ).then((response)=>{
    return response.json()

  }).then((res)=>{
    console.log(res)
  })

}
export function updateConfirmAvailabilityForm(form){
  return(dispatch)=>{
    dispatch({
      type:ACTIONS.UPDATE_CONFIRM_AVAILABILITY_FORM,
      ...form,
    })
  }
}
export function submitConfirmAvailability(uuid,fields){
  let url = AIP_DJAPI_URLS.CONFIRM_AVAILABILITY+"?"+getUuidParam(uuid)
  const form = new FormData()
  fields.first_name && form.append('first_name', fields.first_name)
  fields.last_name && form.append('last_name', fields.last_name)
  fields.email && form.append('email', fields.email)
  fields.phone_number && form.append('phone_number', fields.phone_number)
  fields.message && form.append('message', fields.message)
  console.log(url)
  AIP_DJAPI(
    url,{
      method:'PUT',
      body: form,
    }
  ).then((response)=>{
    return response.json()

  }).then((res)=>{
    console.log(res)
  })
}
export function getSiteSkin(uuid){
  return function(dispatch){
    let url = AIP_DJAPI_URLS.SKIN+"?"+getUuidParam(uuid)
    AIP_DJAPI(url).then((response)=>{
      if(response.status === 200){
        return response.json()
      }
      else{
        return null
      }
      }).then((res)=>{
        if(res){
          dispatch({
            type:ACTIONS.GET_SITE_SKIN,
            skin:res
          })
        }
      })
  }

}
export function getSiteConfig(uuid){
  return function(dispatch){
    let url = AIP_DJAPI_URLS.CONFIG+"?"+getUuidParam(uuid)
    AIP_DJAPI(url).then((response)=>{
        return response.json()
      }).then((res)=>{
        if(res){
          dispatch({
            type:ACTIONS.GET_SITE_CONFIG,
            config:res
          })
        }
      })
  }
}
export function vdpTracking(uuid){
  let url = AIP_DJAPI_URLS.TRACKING+"?"+getUuidParam(uuid)
  let form = new FormData()
  form.append("original_referer",document.referrer)
  AIP_DJAPI(url,{
    method:'PUT',
    body: form,
  }).then((response)=>{

    })
}
export function getDjapiHostedModule(modules,index){
  return function(dispatch){
    AIP_DJAPI(modules[index].url).then((response)=>{
      return response.json()
    }).then((res)=>{
      if(res){
        modules[index].payload = res
        dispatch({
          type: ACTIONS.GET_VEHICLE_MODULES,
          vehicleModules: modules
        })
      }
    })
  }

}
const DEFAULT_APP_UTILS={
  checkV3Migrated,
  getVehiclePage,
  getVehicleModules,
  getVehicleImages,
  getSalespersonInfo,
  showModal,
  closeModal,
  updateZoomMeetingRequestForm,
  submitZoomMeetingRequest,
  updateConfirmAvailabilityForm,
  submitConfirmAvailability,
  getSiteSkin,
  getSiteConfig,
  vdpTracking,
  getDjapiHostedModule,
}
export default DEFAULT_APP_UTILS
