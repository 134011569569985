import React,{ useState } from 'react'
import './styles/zoomMeetingRequestModal.scss'
import { connect } from 'react-redux'
import {updateZoomMeetingRequestForm} from '../defaultAppUtils'
import validateEmail from '../../../utils/validateEmail'
import InputMask from 'react-input-mask';
import DEFAULT_APP_UTILS from '../defaultAppUtils'
import VideoCameraIconOutlined from '../../common/common-assets/icons/videocameraiconoutlined'
import PersonalVideoIcon from '../../common/common-assets/icons/personalVideoIcon'

function ZoomMeetingRequestModal(props){
  let {zoomMeetingRequestForm, vehiclePage, connectUpdateZoomMeetingRequestForm, uuid} = props
  let {first_name, last_name, email, phone_number, message} = zoomMeetingRequestForm
  const [attemptedSubmit,setAttemptedSubmit] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [showForm, setShowForm] = useState(false)
  let validateForm = () =>{
    return( first_name.length && validateEmail(email))
  }
  let submit = () =>{
    setAttemptedSubmit(true)
    if(validateForm()){
      setSubmitted(true)
      DEFAULT_APP_UTILS.submitZoomMeetingRequest(
        uuid,
        {
          first_name,
          last_name,
          email,
          phone_number,
          message,
        }
      )
    }
  }
  if(submitted){
    return(
      <div className="zoom-meeting-request-modal">
        <div className="zoom-meeting-request-success">
          Thank you for contacting us!
          <br/>
          We are confirming this vehicle's availability and a dealer representative will contact you shortly.
        </div>
      </div>
    )
  }
  else if(showForm){
    return(
      <div className="zoom-meeting-request-modal">
        <div className="zoom-meeting-request-header">
          ZOOM MEETING REQUEST FOR
          <br/>
          <span>
              {`${vehiclePage.year} ${vehiclePage.make.toUpperCase()} ${vehiclePage.model.toUpperCase()}`}
          </span>
        </div>
        <div className="zoom-meeting-request-form-container">
          <form onSubmit={(e)=>{
            e.preventDefault()
            submit()
          }}
            className="zoom-meeting-request-form"
          >
            <div className={`zoom-meeting-request-form-first-name ${attemptedSubmit&&!first_name.length?'zoom-meeting-request-form-issue':'zoom-meeting-request-form-required'}`} >
              <label hidden for="zoom-meeting-request-first-name">First Name</label>

              <input
                alt="First Name Required"
                id="zoom-meeting-request-first-name"
                value={first_name}
                placeholder="First Name*"
                onChange={e => {
                    connectUpdateZoomMeetingRequestForm(
                      {
                        ...zoomMeetingRequestForm,
                        first_name: e.target.value
                      }
                    )
                }}
              />
            </div>
            <div className="zoom-meeting-request-form-last-name">
              <label hidden for="zoom-meeting-request-last-name">Last Name</label>

              <input
                id="zoom-meeting-request-last-name"
                value={last_name}
                placeholder="Last Name"
                onChange={e => {
                    connectUpdateZoomMeetingRequestForm(
                      {
                        ...zoomMeetingRequestForm,
                        last_name: e.target.value
                      }
                    )
                }}
              />
            </div>
            <div className={`zoom-meeting-request-form-first-name ${attemptedSubmit&&!validateEmail(email)?'zoom-meeting-request-form-issue':'zoom-meeting-request-form-required'}`}>
              <label hidden for="zoom-meeting-request-email">Email</label>

              <input
                alt="Email Required"
                id="zoom-meeting-request-email"
                value={email}
                placeholder="Email*"
                onChange={e => {
                    connectUpdateZoomMeetingRequestForm(
                      {
                        ...zoomMeetingRequestForm,
                        email: e.target.value
                      }
                    )
                }}
              />
            </div>
            <div className="zoom-meeting-request-form-phone-number">
              <label hidden for="zoom-meeting-request-phone-number">Phone Number</label>

              <InputMask
                id="zoom-meeting-request-phone-number"
                value={phone_number}
                placeholder="(555) 555-5555"
                mask={'(999) 999-9999'}
                onChange={e => {
                    connectUpdateZoomMeetingRequestForm(
                      {
                        ...zoomMeetingRequestForm,
                        phone_number: e.target.value
                      }
                    )
                }}
              />
            </div>
            <div className="zoom-meeting-request-form-message">
              <label hidden for="zoom-meeting-request-message">Message</label>

              <textarea
                id="zoom-meeting-request-message"
                value={message}
                placeholder="Add message"
                onChange={e => {
                    connectUpdateZoomMeetingRequestForm(
                      {
                        ...zoomMeetingRequestForm,
                        message: e.target.value
                      }
                    )
                }}
              />
            </div>
            <div className="zoom-meeting-request-form-submit">
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
    )
  }
  return(
    <div className="zoom-meeting-request-modal">
      <div className="zoom-meeting-request-mode-options">
        <div
          className="zoom-meeting-request-mode-option"
          onClick={
            ()=>{
              setShowForm(true)
            }
          }
        >
          Request Zoom Meeting
          <br/>
          <VideoCameraIconOutlined/>
        </div>
        <a
          className="zoom-meeting-request-mode-option"
          href="https://zoom.autoipacket.com/"
          target="_blank"
        >
          Join Zoom Meeting
          <br/>
          <PersonalVideoIcon/>
        </a>
      </div>
    </div>
  )
}
const mapStateToProps = state =>({
  vehiclePage: state.defaultApp.vehiclePage,
  zoomMeetingRequestForm: state.defaultApp.zoomMeetingRequestForm,
  uuid: {
    vin: state.urlInfo.vin,
    packet_share_uuid: state.urlInfo.packet_share_uuid
  },
})
const mapDispatchToProps = {
  connectUpdateZoomMeetingRequestForm:updateZoomMeetingRequestForm,
}
export default connect(mapStateToProps,mapDispatchToProps)(ZoomMeetingRequestModal)
