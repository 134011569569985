import React from 'react'
import './styles/vehiclePageModal.scss'
function VehiclePageSliderModal(props){
  let{vehicleImages} = props
  return(
      <div className="vps-modal">
        {vehicleImages.map((src,i)=>{
          return(
            <img src={src} alt={`Vehicle Image ${i}`}/>
          )
        })}
      </div>
  )
}
export default VehiclePageSliderModal
