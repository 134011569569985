import React from 'react'
import DefaultHeader from './defaultHeader.js'
import DefaultFooter from './defaultFooter.js'
import VehiclePage from './pages/vehiclePage'
import DEFAULT_APP_UTILS from './defaultAppUtils'
import './defaultApp.scss'
import { connect } from 'react-redux'

import { Route, Switch, Redirect } from 'react-router-dom'

function DefaultApp(props){
  return(
    <div className="default-app">
      <DefaultHeader {...props}/>
        <Switch>
          <Route path="/*" render={()=>{
              return <VehiclePage {...props}/>
            }}
          />
        </Switch>
      <DefaultFooter/>
    </div>
  )
}
const mapStateToProps = state =>({
  ...state.defaultApp
})
const mapDispatchToProps = {
  showModalConnect:DEFAULT_APP_UTILS.showModal,
}
export default connect(mapStateToProps,mapDispatchToProps)(DefaultApp)
