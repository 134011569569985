import React,{useState} from 'react'
import DefaultNavLinks from './defaultNavLinks.js'
import './defaultHeader.scss'
import { connect } from 'react-redux'
import DEFAULT_APP_UTILS from './defaultAppUtils'
import formatPhoneNumber from '../../utils/formatPhoneNumber'
import IPacketIcon from '../common/common-assets/icons/iPacketIcon'


const exampleStore = {
  salespersonName: 'Ron Carter',
  storeName:'Ford/CDJR',
  storeNumber:'(844) 327-7778'
}

function DefaultHeader(props){
  let{vehiclePage, showModalConnect, salespersonInfo, skin} = props
  const [showDefaultLogo,setShowDefaultLogo] = useState(false)
  const renderIcon = ()=>{
    if(skin && skin.logo_full && !showDefaultLogo){
      return(
        <img src={skin.logo_full} alt={vehiclePage.store.full_name} onError={()=>{setShowDefaultLogo(true)}}/>
      )
    }
    return(
      <IPacketIcon/>
    )
  }

  let getHeaderRightSection = () => {
    if(salespersonInfo){
      return(
        <div className="header-right-section">
          <div className="header-store-info-container">
            <div className="header-salesperson-info">
                {`${salespersonInfo.sales_person_name}`}
              <br/>
              {`${salespersonInfo.store_name}`}
              <br/>
              <span>
                {formatPhoneNumber(salespersonInfo.phone)}
              </span>
            </div>
            <div className="header-salesperson-profile-image">
              <img src={salespersonInfo.image} alt={salespersonInfo.sales_person_name}/>
            </div>
          </div>
        </div>
      )
    }
    return(
      <div className="header-right-section">
        <div className="header-store-info-container">
          <div className="header-store-info">
            {`${vehiclePage.store.full_name}`}
            <br/>
            <span className="header-store-number">
              {formatPhoneNumber(vehiclePage.store.phone_number)}
            </span>
          </div>
        </div>
      </div>
    )
  }

  if(vehiclePage){
    return(
      <header>
        <div className="header-content">
          <div className="header-left-section">
            <div className="header-logo">
              {renderIcon()}
            </div>
          </div>
          <div className="header-center-section">
            <div className="header-center-section-static">
              <DefaultNavLinks showModalConnect={showModalConnect}/>
            </div>
            <div className="header-center-section-floating">
              <DefaultNavLinks showModalConnect={showModalConnect} color={"white"}/>
            </div>
          </div>
          {getHeaderRightSection()}
        </div>
      </header>
    )
  }
  return(<React.Fragment/>)
}

const mapDispatchToProps={
  showModalConnect:DEFAULT_APP_UTILS.showModal,
}
export default connect(null,mapDispatchToProps) (DefaultHeader)
