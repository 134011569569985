import React from 'react'
import './styles/moduleiFrame.scss'

function ModuleiFrame(props){
  let {module} = props
  return(
    <div className="module-iframe">
      <iframe
        src={module.url}
        key={module.label}
        view="Fit"
        aria-label={`${module.label}-pdf-img`}
      />
    </div>
  )
} export default ModuleiFrame
